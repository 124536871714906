/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {


  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages



        // Debouncer helper para resize listener
        var debouncer = function(func, timeout) {
          var timeoutID = timeout || 200;
          return function() {
            var scope = this;
            var args  = arguments;
            clearTimeout( timeoutID );
            timeoutID = setTimeout( function() {
              func.apply( scope, Array.prototype.slice.call( args ) );
            }, timeout );
          };
        };





        // Detectar dispositivos touch
        function is_touch_device() {
          return 'ontouchstart' in window || navigator.maxTouchPoints;
        }

        if ( is_touch_device() ) {
          $('html').addClass('touch');
        }





        // Select2 setup
        $.fn.select2.defaults.set( 'theme', 'bootstrap' );

        $('.form-control.select2').select2({
          width: 'auto', // 'resolve'
          minimumResultsForSearch: -1,
          // dropdownParent: $(this).parent(),
          // dropdownAutoWidth: true
        });





        // Selects
        $('select').click(function() {
          if ( this.value !== '' ) {
            $(this).addClass('selected-option');
          }
        });





        // File inputs
        // https://markusslima.github.io/bootstrap-filestyle/1.2.3/
        (function() {
          var lang = $('html').attr('lang');
          var buttonText;
          var placeholder;

          if ( lang === 'en-US' ) {
            buttonText = 'Find';
            placeholder = 'PDF / 2MB Max';
          } else {
            buttonText = 'Adjuntar';
            placeholder = 'PDF / Máximo 2MB';
          }

          $('.file-input-pdf').filestyle({
            // input: false,
            // icon: false,
            buttonText: buttonText,
            buttonName: 'btn btn-file-upload text-none text-medium text-600 pl10 pr10',
            // size: 'sm',
            iconName: 'fa fa-file-pdf-o',
            // disabled: true,
            buttonBefore: true,
            // badge: true,
            placeholder: placeholder
          });
        })(); // File inputs





        // Scrolled
        function getScrolled(){
          if ( $(window).scrollTop() > 60 ) {
            $('body').addClass('scrolled');
          } else {
            $('body').removeClass('scrolled');
          }

          if ( $(window).scrollTop() > 40 ) {
            $('body').addClass('scrolled-mobile');
          } else {
            $('body').removeClass('scrolled-mobile');
          }
        }

        getScrolled();

        $(window).scroll(function() {
          getScrolled();
        });





        // Image replace
        // Reemplazar imagen como background css .__img-replace
        // Gestionar aliniación de la imagen. data-bg-position-x, data-bg-position-y
        (function() {

          var imageReplace = function() {
            var $elements = $('.__img-replace');

            $elements.each(function() {
              var $image = $(this).find('img:first-child');
              var $src   = null;

              if ( $('body').hasClass('ie') || $('body').hasClass('safari') ) {
                $src = $image.prop('src');
              } else {
                $src = $image.prop('currentSrc');
              }

              var url = 'url("' + $src + '")';

              $(this).css({
                'background-image': url
              });
            });
          };

          $('.__img-replace').imagesLoaded(function() {
            imageReplace();
          });

          if ( ! $('body').hasClass('ie') || ! $('body').hasClass('safari') ) {
            $(window).resize( debouncer(function() {
              // imageReplace();
              $('.__img-replace').imagesLoaded(function() {
                imageReplace();
              });
            }));
          }

        })();





        // Efecto Highlight scroll a #newsletter
        (function() {

          var $input     = $('footer #newsletter-email');
          var $formGroup = $input.closest('.form-group');

          $('a[href="#newsletter"]').click(function() {

            $formGroup.addClass('highlight-warning');

            setTimeout(function() {
              $input.focus();
            }, 1000);

            setTimeout(function() {
              $formGroup.removeClass('highlight-warning');
            }, 4000);

          });

        })();





        // Offcanvas responsive
        (function() {

          $('.navbar-offcanvas .nav .menu-item-has-children').prepend('<span class="folder"><i class="fa fa-2x fa-angle-down"></i><i class="fa fa-2x fa-angle-up"></i></span>');

          function WidthChange(mq) {
            var $folder = $('.navbar-offcanvas .nav .menu-item-has-children > .folder');
            var $active = $('.navbar-offcanvas .nav .menu-item-has-children.active');

            if ( mq.matches ) {
              $folder.parent().not('.active').removeClass('open').find('.sub-menu').slideUp('fast');
              $active.addClass('open').find('.sub-menu').slideDown('fast');

              $folder.on('click', function() {
                var $thisItem = $(this).parent();
                $(this).closest('.nav').find('.menu-item-has-children').not($thisItem).removeClass('open');
                $(this).closest('.nav').children('.menu-item-has-children').not($thisItem).children('.sub-menu').slideUp('fast');

                $(this).siblings('.sub-menu').slideToggle('fast');
                $thisItem.toggleClass('open');
              });
            } else {
              $folder.off('click');
              $folder.siblings('.sub-menu').show();
            }
          }

          if ( matchMedia ) {
            const mq = window.matchMedia('(max-width: 991px)');
            mq.addListener(WidthChange);
            new WidthChange(mq);
          }

          $(window).resize( debouncer(function() {
            $('body').removeClass('offcanvas-stop-scrolling');
          }));

        })();





        // --------------------------------------------------------------------
        // Widgets
        // --------------------------------------------------------------------

        // Masonry
        var masonryOptions = {
          percentPosition: true,
          columnWidth: '.widget-sizer',
          itemSelector: '.widget',
          transitionDuration: '300ms',
          gutter: 30,
          // initLayout: false
          // containerStyle: null,
          // horizontalOrder: true,
          // initLayout: false,
          // stagger: 100
        };

        var $sidebars = $('.sidebar, .sidebar-contenido').not('.sidebar-nav').masonry(masonryOptions);

        setTimeout( function() {
          $sidebars.masonry('layout');
        }, 300 );

        $(window).load(function() {
          $sidebars.masonry('layout');
        });





        // Menu widget
        (function() {

          var $menu    =  $('.widget_nav_menu .menu');
          var $menuItems = $menu.children('.menu-item-has-children');
          var $menuItemsActive = $menu.children('.menu-item-has-children.active');

          // No subrayar item (ver _sidebar.scss .active-parent)
          $menu.find('.sub-menu .sub-menu > .active').parent('.sub-menu').closest('li').addClass('active-parent');

          $menuItems.children('a').prepend('<span class="folder"><i class="fa fa-lg fa-caret-right"></i><i class="fa fa-lg fa-caret-down"></i></span>');

          var $folder = $menuItems.children('a').children('.folder');

          $folder.parent().not('.active').removeClass('open').find('.sub-menu').slideUp('fast');
          $menuItemsActive.addClass('open').children('.sub-menu').slideDown('fast');

          $folder.parent().on('click', function(event) {
            if ( $(event.target).is('span, i') ) {
              event.preventDefault();
              // event.stopPropagation();
            }
          });

          $folder.on('click', function() {
            var $thisItem = $(this).closest('li');

            $(this).closest('.menu').find('.menu-item-has-children').not($thisItem).removeClass('open');
            $(this).closest('.menu').children('.menu-item-has-children').not($thisItem).children('.sub-menu').slideUp('fast');

            $thisItem.children('.sub-menu').slideToggle('fast');
            $thisItem.toggleClass('open');

            setTimeout( function() {
              $sidebars.masonry('layout');
            }, 300 );
          });

        })();





        // Collapsed Archives Widget
        (function() {

          var $menu = $('.widget_collapsed_archives_widget .collapsed-archives > ul');
          var $menuItems = $menu.children('li');

          $menu.find('label, input').remove();

          $menuItems.children('a').prepend('<span class="folder"><i class="fa fa-lg fa-caret-right"></i><i class="fa fa-lg fa-caret-down"></i></span>');

          var $folder = $menuItems.children('a').children('.folder');

          $folder.parent().next('ul').slideUp('fast');

          $folder.parent().on('click', function(event) {
            if ( $(event.target).is('span, i') ) {
              event.preventDefault();
            }
          });

          $folder.on('click', function() {
            var $thisItem = $(this).closest('li');

            $(this).closest('ul').children('li').not($thisItem).removeClass('open');
            $(this).closest('ul').children('li').not($thisItem).children('ul').slideUp('fast');

            $thisItem.children('ul').slideToggle('fast');
            $thisItem.toggleClass('open');

            setTimeout( function() {
              $sidebars.masonry('layout');
            }, 300 );
          });

          // Active class
          if ( $('body').hasClass('date') ) {
            var year;
            var month;
            var day;

            if ( $('body').hasClass('date-year') ) {
              year = $('body').attr('class').match(/\bdate-year-(\d+)\b/)[1];
            }

            if ( $('body').hasClass('date-month') ) {
              month = $('body').attr('class').match(/\bdate-month-(\d+)\b/)[1];
            }

            // if ( $('body').hasClass('date-day') ) {
            //   day = $('body').attr('class').match(/\bdate-day-(\d+)\b/)[1];
            // }

            $menuItems.each(function() {

              // Año
              var yearText = $(this).children('a').clone().children().remove().end().text();

              if ( yearText === year ) {
                $(this).addClass('active').children('a').find('.folder').click();
              }

              // Mes
              var $monthItems = $(this).children('ul').children('li');


              $monthItems.each(function() {
                var monthText = $(this).children('a').text();

                if ( monthText === 'enero' || monthText === 'january' ) {
                  monthText = '01';
                }

                if ( monthText === 'febrero' || monthText === 'february' ) {
                  monthText = '02';
                }

                if ( monthText === 'marzo' || monthText === 'march' ) {
                  monthText = '03';
                }

                if ( monthText === 'abril' || monthText === 'april' ) {
                  monthText = '04';
                }

                if ( monthText === 'mayo' || monthText === 'may' ) {
                  monthText = '05';
                }

                if ( monthText === 'junio' || monthText === 'june' ) {
                  monthText = '06';
                }

                if ( monthText === 'julio' || monthText === 'july' ) {
                  monthText = '07';
                }

                if ( monthText === 'agosto' || monthText === 'august' ) {
                  monthText = '08';
                }

                if ( monthText === 'septiembre' || monthText === 'september' ) {
                  monthText = '09';
                }

                if ( monthText === 'octubre' || monthText === 'october' ) {
                  monthText = '10';
                }

                if ( monthText === 'noviembre' || monthText === 'november' ) {
                  monthText = '11';
                }

                if ( monthText === 'diciembre' || monthText === 'december' ) {
                  monthText = '12';
                }

                if ( month === monthText ) {
                  $(this).addClass('active');
                }
              }); // each

            }); // each
          }

        })();





        // Image widget
        // Establecer title del enlace a partir del title opcional de la imagen
        (function() {

          var $widget =  $('.widget_media_image');

          $widget.each(function() {
            var title = $(this).find('img').attr('title');
            $(this).children('a').attr('title', title);
          }); // each

        })();





        // --------------------------------------------------------------------
        // Sliders
        // --------------------------------------------------------------------

        // Slider default
        (function() {

          var $sliderMain = $('.slider-main');
          var $donacionModule = $sliderMain.find('.slick-module-donacion');

          $sliderMain.slick({
            // rows: 0,
            accessibility: false,
            autoplay: true,
            autoplaySpeed: 4000,
            speed: 600,
            fade: true,
            cssEase: 'cubic-bezier(.5, 0, 0, 1)', // custom
            focusOnSelect: false,
            focusOnChange: false,
            pauseOnHover: false,
            pauseOnDotsHover: true,
            arrows: false,
            dots: true,
            appendDots: $('.section-slider-main').find('.dots-container > .container')
          });

          $donacionModule.each(function() {
            var $input = $(this).find('#donacion-directa');
            var $btn = $(this).find('#donacion-directa-btn');

            // Forzar pausa slider si estas llenado el input de donación directa
            $input.on('click', function() {
              $sliderMain.slick('slickPause');
            });

            var initialVal = $input.val();
            var url = $btn.attr('href');
            var cleanUrl = url.split('?')[0];
            var donation = $.query.get('donation');

            // Sin decimales
            // https://stackoverflow.com/questions/469357/html-text-input-allows-only-numeric-input
            $input.keydown(function(e) {
              // Allow: backspace, delete, tab, escape, enter and not . (190)
              if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110]) !== -1 ||
                // Allow: Ctrl/cmd+A
                (e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true)) ||
                // Allow: Ctrl/cmd+C
                (e.keyCode === 67 && (e.ctrlKey === true || e.metaKey === true)) ||
                // Allow: Ctrl/cmd+X
                (e.keyCode === 88 && (e.ctrlKey === true || e.metaKey === true)) ||
                // Allow: home, end, left, right
                (e.keyCode >= 35 && e.keyCode <= 39 )) {
                  // let it happen, don't do anything
                  return;
              }
              // Ensure that it is a number and stop the keypress
              if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
                e.preventDefault();
              }
            });

            // Sincronizar href del botón con valor del input
            $input.on('input', function(object) {
              var val = object.target.value;

              if ( val === '' ) {
                val = initialVal;
              }

              var newParams = $.query.set('donation', val).toString();
              $btn.attr('href', cleanUrl + newParams);
            });

            $input.next().on('click', function() {
              $(this).prev().focus();
            });

            // Firefox cursor bug
            // https://github.com/kenwheeler/slick/issues/2597
            if ( $('body').hasClass('firefox') ) {
              $input.click(function() {
                var el = $(this);
                var position = 'static';
                el.css({position: 'relative'});
                setTimeout(function() {
                  el.focus();
                  el.css({position: position});
                }, 0);
              });
            }

          });

        })();





        // Slider Page
        (function() {

          var $sliderPage = $('.slider-page > .slider');

          // Slick Cloned ACF Map: Ver google-maps-functions.js

          $sliderPage.slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            rows: 0,
            autoplay: true,
            autoplaySpeed: 4000,
            speed: 600,
            cssEase: 'cubic-bezier(.5, 0, 0, 1)', // custom
            pauseOnFocus: true,
            pauseOnHover: true,
            pauseOnDotsHover: true,
            draggable: true,
            touchMove: true,
            arrows: true,
            prevArrow: $('.slider-page-prev'),
            nextArrow: $('.slider-page-next'),
            dots: true,
            appendDots: $('.section-slider-page').find('.dots-container')
          });

          // Habilitar pausa en foco si hay mapa
          // Deshablitar drag y touch si hay mapa en el slider
          if ( $sliderPage.find('.acf-map').length !== 0 ) {
            $sliderPage.slick('slickSetOption', 'pauseOnFocus', true, false);
            $sliderPage.slick('slickSetOption', 'draggable', false, false);
            $sliderPage.slick('slickSetOption', 'touchMove', false, false);
          }

        })();





        // Slider Modules
        (function() {

          var $sliderModules = $('.slider-modules > .slider');

          $sliderModules.slick({
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 3,
            rows: 0,
            autoplay: true,
            autoplaySpeed: 4000,
            speed: 600,
            useTransform: true,
            cssEase: 'cubic-bezier(.5, 0, 0, 1)', // custom
            pauseOnHover: true,
            pauseOnDotsHover: true,
            dots: true,
            arrows: true,
            prevArrow: $('.slider-modules-prev'),
            nextArrow: $('.slider-modules-next'),

            responsive: [
              {
                breakpoint: 768, // max-width 767px / screen-xs-max
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  // arrows: true
                }
              },
              {
                breakpoint: 992, // max-width 991px / screen-sm-max
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  // arrows: true
                }
              },
              {
                breakpoint: 1200, // max-width 1199px / screen-md-max
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3
                }
              },
              {
                breakpoint: 1440, // max-width 1439px / screen-lg-max
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3
                }
              }
            ]
          });

        })();





        // Slider Modulos Imagen
        (function() {

          var $sliderModulosImagen = $('.slider-modulos-imagen > .slider');

          var unCenter = function() {
            var totalSlides = $sliderModulosImagen.find('.slick-track > div').length;

            if ( totalSlides <= 3 ) {
              $sliderModulosImagen.find('.slick-slide').removeClass('slick-center').addClass('slick-uncenter');
            }
          };

          // Quitar center mode cuando son menos de 3
          $sliderModulosImagen.on('init reInit afterChange', function(event, slick, currentSlide, nextSlide) {
            unCenter();
          });

          $sliderModulosImagen.slick({
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            rows: 0,
            autoplay: false,
            autoplaySpeed: 4000,
            speed: 600,
            useTransform: true,
            cssEase: 'cubic-bezier(.5, 0, 0, 1)',
            pauseOnHover: true,
            pauseOnDotsHover: true,
            dots: false,
            arrows: true,
            prevArrow: $('.slider-modulos-imagen-prev'),
            nextArrow: $('.slider-modulos-imagen-next'),

            centerMode: true,
            centerPadding: 0,

            responsive: [
              {
                breakpoint: 768, // max-width 767px / screen-xs-max
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  autoplay: false, // true
                  dots: true,
                  centerMode: false
                }
              },
              {
                breakpoint: 992, // max-width 991px / screen-sm-max
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  autoplay: false, // true
                  dots: true,
                  centerMode: false
                }
              },
              {
                breakpoint: 1200, // max-width 1199px / screen-md-max
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  autoplay: false,
                  dots: false,
                }
              },
              {
                breakpoint: 1440, // max-width 1439px / screen-lg-max
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  autoplay: false,
                  dots: false,
                }
              }
            ]
          })
          // Avanzar al clicar en un slide
          .on('click', '.slick-slide', function(e) {
            e.stopPropagation();
            var index = $(this).data('slick-index');

            if ( $sliderModulosImagen.slick('slickCurrentSlide') !== index ) {
              $sliderModulosImagen.slick('slickGoTo', index);
            }
          });

          unCenter();

        })();





        // Slider Objetivos Proyecto
        (function() {

          var sliderProyectoObjetivos = function() {
            var $sliderObjetivos = $('.slider-objetivos > .slider');

            if ( $('.slider-objetivos > .slick-initialized').length ) {
              $sliderObjetivos.slick('unslick');
            }

            $sliderObjetivos.slick({
              mobileFirst: true,
              infinite: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              rows: 0,
              autoplay: true,
              autoplaySpeed: 4000,
              speed: 600,
              useTransform: true,
              cssEase: 'cubic-bezier(.5, 0, 0, 1)', // custom
              pauseOnFocus: true,
              pauseOnHover: false,
              pauseOnDotsHover: true,
              arrows: false,
              dots: true,
              appendDots: $('.component-slider-objetivos').find('.dots-container'),

              responsive: [
                {
                  breakpoint: 767, // min-width 768px / screen-sm-min
                  settings: 'unslick'
                }
              ]
            });
          };

          sliderProyectoObjetivos();

          $(window).resize( debouncer(function() {
            if ( $(window).width() < 768 ) {
              sliderProyectoObjetivos();
            }
          }));

        })();

        // Sliders ------------------------------------------------------------





        // Enlaces externos
        $('a[rel*=external]').click(function() {
          window.open(this.href);
          return false;
        });





        // FitVids
        $('.entry-content-asset').fitVids();





        // Tooltips
        $('[data-toggle="tooltip"]').tooltip();
        // $('.tooltiped').tooltip();
        // $('.tagcloud > a').tooltip();





        // Ocultar tooltips nativos
        var noTooltip = function(className) {
          $(className).hover(function() {
            $(this).attr('tooltip-data', $(this).attr('title'));
            $(this).removeAttr('title');
          }, function() {
            $(this).attr('title', $(this).attr('tooltip-data'));
            $(this).removeAttr('tooltip-data');
          });
        };
        noTooltip('.no-tooltip');

        $(document).on('facetwp-loaded', function() {
          noTooltip('.no-tooltip');
        });





        // Scroll suave
        $('.smooth-scroller').each(function() {
          $(this).click(function(event) {
            event.preventDefault();

            var $el          = $(this.getAttribute('href'));
            var elOffset     = $el.offset();
            var scrollOffset = $(this).data('scrollOffset');

            if ( ! scrollOffset ) {
              scrollOffset = 0;
            }

            $('html, body').stop().animate({ scrollTop: elOffset.top - scrollOffset }, 600, 'easeInOutQuart');
          });
        });

        $('.widget_media_image').find('a[href="#newsletter"]').each(function() {
          $(this).click(function(event) {
            event.preventDefault();

            var $el          = $(this.getAttribute('href'));
            var elOffset     = $el.offset();
            var scrollOffset = $(this).data('scrollOffset');

            if ( ! scrollOffset ) {
              scrollOffset = 0;
            }

            $('html, body').stop().animate({ scrollTop: elOffset.top - scrollOffset }, 600, 'easeInOutQuart');
          });
        });





        // ScrollUp
        (function() {
          $.scrollUp({
            scrollName: 'scrollUp', // Element ID
            // scrollDistance: 300, // Distance from top/bottom before showing element (px)
            // scrollFrom: 'top', // 'top' or 'bottom'
            scrollSpeed: 600, // Speed back to top (ms)
            easingType: 'easeInOutQuart', // Scroll to top easing (see http://easings.net/)
            // animation: 'fade', // Fade, slide, none
            // animationSpeed: 200, // Animation speed (ms)
            // scrollTrigger: false, // Set a custom triggering element. Can be an HTML string or jQuery object
            // scrollTarget: false, // Set a custom target element for scrolling to. Can be element or number
            scrollText: '<i class="fa fa-4x fa-angle-up"></i>', // Text for element, can contain HTML
            // scrollTitle: false, // Set a custom <a> title if required.
            // scrollImg: false, // Set true to use image
            // activeOverlay: false, // Set CSS color to display scrollUp active point, e.g '#00FFFF'
            // zIndex: 2147483647 // Z-Index for the overlay
          });
        })();





        // Headroom
        $('#main-header > .navbar-main, #scrollUp').headroom(
          {
            // vertical offset in px before element is first unpinned
            offset : 480,
            // // scroll tolerance in px before state changes
            // tolerance : 0,
            // // or scroll tolerance per direction
            // tolerance : {
            //     down : 0,
            //     up : 0
            // },
            // // element which is source of scroll events. Defaults to window
            // scroller : element,
            // // css classes to apply
            // classes : {
            //   // when element is initialised
            //   initial : 'headroom',
            //   // when scrolling up
            //   pinned : 'headroom--pinned',
            //   // when scrolling down
            //   unpinned : 'headroom--unpinned',
            //   // when above offset
            //   top : 'headroom--top',
            //   // when below offset
            //   notTop : 'headroom--not-top',
            //   // when at bottom of scoll area
            //   bottom : 'headroom--bottom',
            //   // when not at bottom of scroll area
            //   notBottom : 'headroom--not-bottom'
            // },
            // // callback when pinned, `this` is headroom object
            // onPin : function() {
            //   $('#scrollUp').addClass('headroom--pinned').removeClass('headroom--unpinned');
            // },
            // // // callback when unpinned, `this` is headroom object
            // onUnpin : function() {
            //   $('#scrollUp').removeClass('headroom--pinned').addClass('headroom--unpinned');
            // },
            // // callback when above offset, `this` is headroom object
            // onTop : function() {},
            // // callback when below offset, `this` is headroom object
            // onNotTop : function() {}
            // // callback at bottom of page, `this` is headroom object
            // onBottom : function() {},
            // // callback when moving away from bottom of page, `this` is headroom object
            // onNotBottom : function() {}
          }
        );





        // Ellipsis multilinea
        (function() {

          $('.ellipsis, .marker-content-proyecto .entry-excerpt').dotdotdot({
            ellipsis: '\u2026', // '&hellip;',
            // wrap: 'word',
            // fallbackToLetter: true,
            // after: null,
            watch: 'window', // false,
            // height: null,
            // tolerance: 0,
            // callback: function( isTruncated, orgContent ) {},
            // lastCharacter: {
            //   remove: [ ' ', ',', ';', '.', '!', '?' ],
            //   noEllipsis : []
            // }
          });
        })();





        // Equal heigh columns
        // https://github.com/liabru/jquery-match-height
        $('.equalheight').matchHeight({
          byRow: false,
          // property: 'height',
          // target: null,
          // remove: false
        });

        $('.equalheight-row').matchHeight({
          // byRow: true,
          // property: 'height',
          // target: null,
          // remove: false
        });

        $('.equalheight-target').matchHeight({
          byRow: true,
          target: $('.target')
        });





        // ----------------------------------------------------------
        // WPCF7
        // ----------------------------------------------------------

        // WPCF7 - Mover input-group-addon
        (function() {
          var $inputGroupAddon = $('.wpcf7-form').find('.input-group-addon');

          $inputGroupAddon.each(function() {
            $(this).insertAfter($(this).parent('.input-group').find('.wpcf7-form-control-wrap > .wpcf7-form-control'));
          });
        })(); // inputGroupAddon


        // WPCF7 - Checkbox label click
        (function() {
          var $label = $('.wpcf7-checkbox').find('.wpcf7-list-item-label');

          $label.each(function() {
            $(this).click(function(e) {
              if ( e.target === this ) {
                $(this).prev().click();
              }
            });
          });
        })(); // Chackboxes


        // WPCF7 - Mover ajax-loader
        $('.ajax-loader').each(function() {
          $(this).insertBefore($(this).prev().find('i'));
          $(this).addClass('fa fa-fw fa-circle-o-notch fa-spin');
        });


        // WPCF7 - Permitir acceptance con botones
        wpcf7.toggleSubmit = function(form, state) {
          var $form = $(form);
          var $submit = $('input:submit, button:submit', $form);

          if ( typeof state !== 'undefined' ) {
            $submit.prop('disabled', ! state);
            return;
          }

          if ( $form.hasClass('wpcf7-acceptance-as-validation') ) {
            return;
          }

          $submit.prop('disabled', false);
          $submit.removeAttr('title'); // Quitar title

          $('.wpcf7-acceptance', $form).each(function() {
            var $span = $(this);
            var $input = $('input:checkbox', $span);

            if ( ! $span.hasClass('optional') ) {
              if ( $span.hasClass('invert') && $input.is(':checked') || ! $span.hasClass('invert') && ! $input.is(':checked') ) {
                $submit.prop('disabled', true);
                return false;
              }
            }
          });
        };


        // WPCF7 - Scroll a comienzo del formulario una vez hecho submit
        (function() {
          var $form = $('.wpcf7');

          $form.each(function() {
            $(this).on('wpcf7:submit', function(event) {
              $('html, body').animate({
                scrollTop: $(this).offset().top-140
              }, 600);
            });
          });
        })();


        // WPCF7 - Google Analytics Tracking Code
        // Eventos WPCF7:
        // - wpcf7:invalid
        // - wpcf7:spam
        // - wpcf7:mailsent
        // - wpcf7:mailfailed
        // - wpcf7:submit
        (function() {
          var $form = $('.wpcf7');

          $form.each(function() {
            var formTitle = $(this).find('input[name="title"]').val();
            var pageUrl = $(this).find('input[name="page-url"]').val();

            // // Submit Test
            // $(this).on('wpcf7:submit', function(event) {
            //   console.log(event);
            //   ga('send', 'event', formTitle, 'TEST', pageUrl);
            // });

            $(this).on('wpcf7:mailsent', function() {
              ga('send', 'event', formTitle, 'Envío correcto', pageUrl);
            });

            $(this).on('wpcf7:mailfailed', function() {
              ga('send', 'event', formTitle, 'Envío fallido', pageUrl);
            });

            $(this).on('wpcf7:spam', function() {
              ga('send', 'event', formTitle, 'Posible spam', pageUrl);
            });
          });
        })();





        // Enlace a modal en barra de cookies
        (function() {
          var lang = $('html').attr('lang');

          $('#cookie_action_close_header_reject').remove();

          if ( lang === 'en-US' ) {
            $('.cookie-law-en').show();
            $('#cookie_action_close_header').text('Accept');
            // $('#cookie_action_close_header_reject').text('Close');
          } else {
            $('.cookie-law-es').show();
          }

          $('#button-modal-cookie-es').click(function(event) {
            event.preventDefault();
            $('#modal-politica-proteccion-datos').modal('show');
          });

          $('#button-modal-cookie-en').click(function(event) {
            event.preventDefault();
            $('#modal-data-protection-policy').modal('show');
          });

          // Aceptar política de cookies en el modal
          $('#modal-politica-proteccion-datos .modal-footer .btn-success, #modal-data-protection-policy .modal-footer .btn-success').click(function() {
            $('#cookie_action_close_header').click();
          });

        })();





        // Menú footer
        (function() {
          var menuSinglesFloat = function() {
            var $menu = $('.content-info-menu .menu');
            var $menuSingles = $menu.children('.menu-item').not('.menu-item-has-children');

            $menuSingles.each(function(index, el) {
              var x = $(this).next().position().left;
              var y = $(this).outerHeight();

              $(this).css('left', x);
              $(this).next().css('margin-top', y);
            });
          };

          $(window).resize( debouncer(function() {
            menuSinglesFloat();
          }));

          $(window).load(function() {
            menuSinglesFloat();
          });

          // menuSinglesFloat();
        })();





        // Progress bars animados
        (function() {
          var $progressbar = $('.progress-bar');
          var width = $progressbar.attr('aria-valuenow');

          $progressbar.each(function() {
            $(this).on('inview', function(event, isInView) {
              if ( isInView ) {
                $(this).css('width', width + '%');
              } else {
                $(this).css('width', '0%');
              }
            });
          }); // each
        })();

        // Odometros animados
        (function() {
          var $odometers = $('.odometro');

          $odometers.each(function() {
            var odo = new Odometer({
              el: this,
              value: 0,
              format: '(,ddd)',
              theme: 'codespa',
              duration: 600
            });

            odo.render();

            var val = $(this).data('odometro');

            $(this).on('inview', function(event, isInView) {
              if ( isInView ) {
                $(this).text(val);
              } else {
                $(this).text('0');
              }
            });
          }); // each
        })();





        // Social Warfare
        (function() {
          var lang = $('html').attr('lang');

          if ( lang === 'es-ES' ) {
            $('.swp_label').text('Total').show();
          }

        })();





      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },





    // Proyectos
    'post_type_archive_codespa_proyecto': {
      init: function() {


        // --------------------------------------------------------------------
        // FacetWP
        // --------------------------------------------------------------------

        // Filters
        // https://select2.org/configuration/options-api
        function renderFilters() {
          $('.filters, .sorter').each( function() {
            $(this).find('.select2').select2({
              width: 'auto', // 'resolve'
              minimumResultsForSearch: -1,
              dropdownParent: $(this),
              dropdownAutoWidth: true
            });
          });
        }
        renderFilters();

        $(document).on('facetwp-loaded', function() {
          renderFilters();
        });





        // Dropdowns
        $(document).on('facetwp-loaded', function() {

          // Reemplzar texto primera opción al estar seleccionada
          $('.facetwp-facet').find('.select2').on('click', function() {
            var $initial = $(this).find('.select2-selection__rendered');
            var $menu = $(this).closest('.filters').children('.select2-container--open');
            var $drop = $menu.find('.select2-dropdown');
            var $reset = $menu.find('.select2-results__option:first-child');

            var resetText = $reset.text();
            var initialText = $initial.text();

            // País
            if ( ($reset.text() === 'País') && ($initial.text() !== 'País') ) {
              // $reset.text('Todos los paises').show();
              $reset.text('Borrar').show();
              $reset.prepend('<i class="fa fa-lg fa-times-circle-o pull-left"></i>');
            }
            // Country
            if ( ($reset.text() === 'Country') && ($initial.text() !== 'Country') ) {
              // $reset.text('All countries').show();
              $reset.text('Clear').show();
              $reset.prepend('<i class="fa fa-lg fa-times-circle-o pull-left"></i>');
            }

            // Beneficiarios
            if ( ($reset.text() === 'Beneficiarios') && ($initial.text() !== 'Beneficiarios') ) {
              // $reset.text('Todos los beneficiarios').show();
              $reset.text('Borrar').show();
              $reset.prepend('<i class="fa fa-lg fa-times-circle-o pull-left"></i>');
            }
            // Beneficiaries
            if ( ($reset.text() === 'Beneficiaries') && ($initial.text() !== 'Beneficiaries') ) {
              // $reset.text('All beneficiaries').show();
              $reset.text('Clear').show();
              $reset.prepend('<i class="fa fa-lg fa-times-circle-o pull-left"></i>');
            }

            // Línea de Actuación
            if ( ($reset.text() === 'Línea de Actuación') && ($initial.text() !== 'Línea de Actuación') ) {
              // $reset.text('Todas las lineas de actuación').show();
              $reset.text('Borrar').show();
              $reset.prepend('<i class="fa fa-lg fa-times-circle-o pull-left"></i>');
            }
            // Line of action
            if ( ($reset.text() === 'Line of action') && ($initial.text() !== 'Line of action') ) {
              // $reset.text('All lines of action').show();
              $reset.text('Clear').show();
              $reset.prepend('<i class="fa fa-lg fa-times-circle-o pull-left"></i>');
            }
          });


          // Sorter
          $('.facetwp-sort').find('.select2').on('click', function() {
            var $initial = $(this).find('.select2-selection__rendered');
            var $menu = $(this).closest('.filters').children('.select2-container--open');
            var $drop = $menu.find('.select2-dropdown');
            var $option = $menu.find('.select2-results__option');

            $menu.addClass('sorter');
            $option.hide();
            $drop.addClass('in');

            $option.each( function() {
              if ( $(this).text() === $initial.text() ) {
                $(this).hide();
              } else {
                $(this).show();
              }
            });
          });

        }); // Dropdowns





        // FacetWP Map
        $(document).on('facetwp-loaded', function() {

          // Animación de marcadores
          if ( FWP_MAP ) {
            FWP_MAP.markersArray.forEach(function(el) {
              el.setAnimation(google.maps.Animation.DROP);
            });
          }

          // Zoom mapa en 5 cuando hay solo un marcador
          if ( FWP.loaded && FWP_MAP.markersArray.length === 1 ) {
            FWP_MAP.map.setZoom(5);
          }

          if ( !FWP.loaded && FWP_MAP.markersArray.length === 1 ) {
            $(window).load(function() {
              FWP_MAP.map.setZoom(5);
            });
          }

          // Fix mapa centrado con América a la izquierda
          // Centrar mapa en 0,0 si el zoom es igual o menor a 2
          setTimeout( function() {
            if ( FWP.loaded && FWP_MAP.markersArray.length >= 1 && FWP_MAP.map.getZoom() <= 2 ) {
              FWP_MAP.map.setCenter({lat:0, lng:0});
            }
          }, 500 );

        }); // FacetWP Map





        // Preloader
        var $loader = $('.main-loop > .loader');

        $(document).on('facetwp-refresh', function() {
          $loader.fadeIn();
        });

        $(document).on('facetwp-loaded', function() {
          $loader.fadeOut();
        });





        // Fade Content
        $(document).on('facetwp-refresh', function() {
          $('.facetwp-template').animate({ opacity: 0 }, 300);
        });

        $(document).on('facetwp-loaded', function() {
          $('.facetwp-template').animate({ opacity: 1 }, 300);
        });





        // Scroll to top al accionar el pager
        $(document).on('facetwp-refresh', function() {
          if ( FWP.loaded ) {
            $('html, body').animate({
              scrollTop: $('.title-bordered-arrow').offset().top-60
              // scrollTop: 61
            }, 600);

            setTimeout( function() {
              $('#main-header > .navbar-main').removeClass('headroom--pinned').addClass('headroom--unpinned');
            }, 700 );
          }
        });





        // // Google Analytics for Facet Pages
        // $(document).on('facetwp-loaded', function() {
        //   if (FWP.loaded) {
        //     ga('send', 'pageview', window.location.pathname + window.location.search);
        //   }
        // });





        // --------------------------------------------------------------------
        // ScrollReveal
        // https://github.com/jlmakes/scrollreveal.js
        // --------------------------------------------------------------------

        window.sr = new ScrollReveal();

        // Quitar clase .sr si ScrollReveal no existe
        if ( !sr.isSupported() ) {
          $('body').removeClass('scrollreveal');
        }

        // Listado de Proyectos
        sr.reveal('.main-loop .reveal', {
          // container: xxx,
          origin: 'bottom',
          distance: '120px',
          duration: 1200,
          // delay: 1000,
          // rotate: { x: 90, y: 0, z: 0 },
          // opacity: 0,
          scale: 0, // 0.9
          easing: 'cubic-bezier(0.6, 0.2, 0.1, 1)',
          // container: window.document.documentElement,
          mobile: false,
          // reset: true,
          // useDelay: 'always', // once, onload
          viewFactor: 0.5, // 0.2
          // viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },

          // Callbacks
          // beforeReveal: function (domEl) {},
          // beforeReset: function (domEl) {},

          // afterReveal: function (domEl) {},
          // afterReset: function (domEl) {}
        });

        $(document).on('facetwp-loaded', function() {
          sr.sync();
        });





      } // init

      // ,
      // finalize: function() {
      //   // JavaScript to be fired on the home page, after the init JS
      // }

    } // post_type_archive_codespa_proyecto





    // ,
    // // About us page, note the change from about-us to about_us.
    // 'about_us': {
    //   init: function() {
    //     // JavaScript to be fired on the about us page
    //   },
    //   finalize: function() {
    //     // JavaScript to be fired after the init JS
    //   }
    // } // about_us

  };


  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
