/**
 * @param {Array or NodeList or Element} elems
 * @param {Number} index
 */
Masonry.prototype.insert = function( elems, index ) {
  var items = this._itemize( elems );
  if ( !items.length ) {
    return;
  }
  // append item elements
  var i, item;
  var len = items.length;
  var fragment = document.createDocumentFragment();
  for ( i=0; i < len; i++ ) {
    item = items[i];
    fragment.appendChild( item.element );
  }
  var beforeElem = this.items[ index + 1 ].element;
  this.element.insertBefore( fragment, beforeElem );
  // insert items
  var spliceArgs = [ index, 0 ].concat( items );
  this.items.splice.apply( this.items, spliceArgs );
  // set flag
  for ( i=0; i < len; i++ ) {
    items[i].isLayoutInstant = true;
  }
  this.layout();
  // reset flag
  for ( i=0; i < len; i++ ) {
    delete items[i].isLayoutInstant;
  }
  this.reveal( items );
};
